<template>
  <div>
    <b-button
      :variant="currentVariant"
      icon-pack="feather"
      icon="icon-link-2"
      class="w-100"

      style="border-radius: 5px!important;height: 30px;"
      @click="shareJobConfirmation"
    >
     

      <div
        class="d-flex justify-content-center align-items-center"
        style="margin-top: -7px!important;"
      >
        <feather-icon
          v-if="$isNotEmpty(currentIcon)"
          :icon="currentIcon"
          class="mr-1"
          style="margin-top: -15px!important;"
        />
        <p style="">
          {{ shareJobText }}
        </p>
      </div>
    </b-button>

    <b-modal
      id="modal-lg"
      ref="first-share-popup"
      v-model="shareJobModal"
      centered
      ok-title="Share & Apply Later"
      ok-variant="outline-secondary"
      cancel-title="Continue Search"
      cancel-variant="success"
      :title="`Selected Job${$length(jobList) > 1 ? 's' : ''}`"
      @ok="proceedFunc"
      @cancel="cancelFunc"
    >
      <div class="">
        <div v-if="getLSJobs()">
          <h6>Roles you have selected to share and apply later:</h6>
          <ul
            class="overflow-auto"
            style="max-height: 10cm;"
          >
            <hr>
            <li
              v-for="(item, index) in getLSJobs()"
              :key="index"
              class="mt-1"
            >
              <b-row>
                <b-col cols="8">
                  <strong>{{ item }}</strong>
                </b-col>
                <b-col cols="4">
                  <FeatherIcon
                    icon="TrashIcon"
                    size="22"
                    style="color: #f37044;"
                  />
                </b-col>

              </b-row>

              <hr class="mb-1">
            </li>

          </ul>

        </div>
        <p>You can share more than 1 job at once. Would you like to continue to search for more jobs?</p>
      </div>

    </b-modal>

  </div>
</template>

<script>
export default {
  props: {
    jobDetails: {
      type: Object,
      default: () => { },
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      modals: {
        confirmation: {
          state: false,
        },
      },
      shareJobText: 'Add Job To Cart',
      jobList: '',
      shareJobModal: false,
      currentIcon: '',
      currentVariant: '',
    }
  },
  computed: {
    jobModal() {
      return this.$store.state.auth.share_job_modal
    },
    shareJobList() {
      return this.$store.state.auth.share_job_list
    },
  },

  watch: {
    shareJobList: {
      handler(newVal, oldVal) {
        if (!Object.keys(newVal).includes(this.jobDetails.id)) {
          this.shareJobText = 'Add Job To Cart'
          this.currentVariant = 'primary'
          this.currentIcon = 'PlusIcon'
        }
      },
      deep: true, // Ensures changes to nested properties or array items are detected
    },
  },
  mounted() {
    this.currentIcon = this.icon
    this.currentVariant = this.variant

    const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
    if (Object.keys(shareJobList).includes(this.jobDetails.id)) {
      this.shareJobText = 'Remove Job From Cart'
      this.currentVariant = 'danger'
      this.currentIcon = 'MinusIcon'
    }
  },
  methods: {

    proceedFunc() {
      this.$emit('next-step', true)
    },
    cancelFunc() {
      const currentPage = this.$route.name
      const startLocation = this.$router.history._startLocation
      if (currentPage === 'kiosk-page-event') {
        if (startLocation.includes('/kiosk/job/')) {
          this.$router.push('/kiosk/events/308c7aad-38ad-4b02-88dd-f934ef1128ca_g')
        }
      }
    },
    getLSJobs() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || null
      let positionText = null
      if (shareJobList) {
        positionText = Object.values(shareJobList).map(item => item.position)
      }
      return positionText
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: 'per Year',
        monthly: 'per Month',
        weekly: 'per Week',
        daily: 'per Day',
        hourly: 'per Hour',
      }

      const cleanedCycle = !this.$isEmpty(dirtyCycle) ? equivCycles[dirtyCycle.toLowerCase()] : ''

      return cleanedCycle
    },
    getEventType(value) {
      if (value === 'virtual') {
        return 'Virtual Interview'
      }
      if (value === 'physical') {
        return 'In-Person Interview'
      }
      if (value === 'speed_interview') {
        return 'Virtual Speed Interview'
      }
      return 'NA'
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (location.postal_code === '000000' || location.postal_code === '999999') {
        currentZone = 'Islandwide'
      } else if (currentZone === undefined || currentZone === null) {
        currentZone = 'NA'
      }
      return `${currentZone} (${location.country}${this.$isEmpty(location.country) ? location.postal_code : ` ${location.postal_code}`})`.replace('999999', '').replace(' null', '').replace('null', '')
      // return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },

    shareJobConfirmation() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
      if (Object.keys(shareJobList).includes(this.jobDetails.id)) {
        delete shareJobList[this.jobDetails.id]
        this.shareJobText = 'Add Job To Cart'
        this.currentVariant = 'primary'
        this.currentIcon = 'PlusIcon'
      } else {
        const salaryDetails = this.$isNotEmpty(this.jobDetails.min_salary) && this.$isNotEmpty(this.jobDetails.max_salary)
          ? `${this.$formatCurrency(this.jobDetails.min_salary)} - ${this.$formatCurrency(this.jobDetails.max_salary)} ${this.cleanCycles(this.jobDetails.salary_cycle)}`
          : 'Undisclosed'
        shareJobList[this.jobDetails.id] = {
          id: this.jobDetails.id,
          position: this.jobDetails.position,
          company: this.jobDetails.company ? this.jobDetails.company : this.jobDetails.company_name,
          salary: salaryDetails,
          location: this.cleanLocation(this.jobDetails.work_location, this.jobDetails.location_zone),
          interview_type: this.getEventType(this.jobDetails.interview_type),
        }
        this.shareJobText = 'Remove Job From Cart'
        this.currentVariant = 'danger'
        this.currentIcon = 'MinusIcon'
      }

      localStorage.setItem('share_job_list', JSON.stringify(shareJobList))
      this.$store.commit('auth/UPDATE_CART_JOBS', Object.keys(shareJobList).length, { root: true })
      this.$store.commit('auth/UPDATE_SHARE_JOB_LIST', shareJobList, { root: true })
      this.jobList = shareJobList
      // this.$simpleConfirmModal(
      //   this,
      //   searchText,
      //   {
      //     okTitle: `Share Job${this.$length(shareJobList) > 1 ? 's' : ''}`,
      //     okVariant: 'outline-secondary',
      //     cancelTitle: 'Continue Search',
      //     cancelVariant: 'success',
      //   },
      // )
      //   .then(value => {
      //     this.$emit('next-step', true)
      //   })
      //   .catch(err => {
      //     const currentPage = _this.$route.name
      //     const startLocation = _this.$router.history._startLocation
      //     if (currentPage === 'kiosk-page-event') {
      //       this.$router.push({ path: startLocation })
      //       if (startLocation.includes('/kiosk/job/')) {
      //         this.$router.push({ path: '/kiosk' })
      //       }
      //     }
      //     // do nothing, confitnue browsing
      //   })
    },
  },
}
</script>

<style></style>
